import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LinqPredicateDTO } from "@eqproject/eqp-filters";
import { environment } from "../../environments/environment";
import { AttachmentDTO } from "../models/attachment.model";
import { AreaTypeDTO } from "../models/generics/areaType.model";
import { ChangeStatusObj } from "../models/generics/changeStatusObj.model";
import { GamificationActionTypeEnum } from "../models/generics/gamification.model";
import { StandingUsersPaginatedDTO, UserDTO, UserStatus, UserType } from "../models/generics/user.model";
import { UsersPaginatedDTO } from "../models/generics/usersPaginatedDTO";

const toBase64 = (file) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });

@Injectable({ providedIn: "root" })

/**
 * Servizio per utenti
 */
export class UserService {
  constructor(private http: HttpClient) {}

  /**
   * Metodo per ottenere tutti gli utenti dall'API
   */

  getAllUsers(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<UsersPaginatedDTO> {
    if (filters == null || filters == undefined) filters = new Array<LinqPredicateDTO>();

    return this.http
      .post<UsersPaginatedDTO>(environment.apiFullUrl + "/User/GetAllUsers/" + pageSize + "/" + pageIndex, filters)
      .toPromise();
  }

  getUserByID(id: number): Promise<UserDTO> {
    return this.http.get<UserDTO>(environment.apiFullUrl + "/User/GetUserById/" + id).toPromise();
  }

  getUserByEmail(email: string): Promise<UserDTO> {
    return this.http.get<UserDTO>(environment.apiFullUrl + "/User/GetUserByEmail/" + email).toPromise();
  }

  saveUser(user: UserDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/SaveUser", user).toPromise();
  }

  preregistration(user: UserDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/Preregistration", user).toPromise();
  }

  deleteUser(id: number): Promise<any> {
    return this.http.delete<any>(environment.apiFullUrl + "/User/" + id).toPromise();
  }

  changeUserStatus(changeStatusObj: ChangeStatusObj): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/ChangeStatusUser", changeStatusObj).toPromise();
  }

  getUserProcessed(userStatus: UserStatus, userType: UserType): Promise<UserDTO> {
    return this.http
      .get<UserDTO>(environment.apiFullUrl + "/User/GetUserProcessedByStatusAndType/" + userStatus + "/" + userType)
      .toPromise();
  }

  getUsersInvited(userID: number): Promise<Array<UserDTO>> {
    return this.http.get<Array<UserDTO>>(environment.apiFullUrl + "/User/GetUsersInvited/" + userID).toPromise();
  }

  getDuplicates(lastUserIteratedID: number): Promise<Array<Array<UserDTO>>> {
    return this.http
      .get<Array<Array<UserDTO>>>(environment.apiFullUrl + "/User/GetDuplicates/" + lastUserIteratedID)
      .toPromise();
  }

  getUsersStanding(
    pageSize: number,
    pageIndex: number,
    filters?: Array<LinqPredicateDTO>
  ): Promise<StandingUsersPaginatedDTO> {
    if (filters == null || filters == undefined) filters = new Array<LinqPredicateDTO>();

    return this.http
      .post<StandingUsersPaginatedDTO>(
        environment.apiFullUrl + "/User/GetUsersStanding/" + pageSize + "/" + pageIndex,
        filters
      )
      .toPromise();
  }

  skipUserProcessed(id: number): Promise<any> {
    return this.http.get<any>(environment.apiFullUrl + "/User/SkipUserProcessed/" + id).toPromise();
  }

  getUsersCount(): Promise<any> {
    return this.http.get<any>(environment.apiFullUrl + "/User/GetUsersCount").toPromise();
  }

  downloadDocument(FK_Document: number): Promise<any> {
    let HTTPOptions: Object = {
      responseType: "blob"
    };
    return this.http
      .get<any>(environment.apiFullUrl + "/User/DownloadDocument/" + FK_Document, HTTPOptions)
      .toPromise();
  }

  downloadTemporaryDocument(doc: AttachmentDTO) {
    const byteCharacters = atob(doc.FileDataBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: doc.ContentType });
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = doc.FileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  resetPassword(data: any): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/ResetPassword", data).toPromise();
  }

  sendMail(subject: string, body: string, recipientEmail: string): Promise<any> {
    return this.http
      .get<any>(environment.apiFullUrl + "/User/SendEmail/" + subject + "/" + body + "/" + recipientEmail)
      .toPromise();
  }

  sendPreregistrationMail(user: UserDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/SendPreregistrationMail", user).toPromise();
  }

  updateUserScore(user: UserDTO, points: number, gamificationActionType: GamificationActionTypeEnum): Promise<any> {
    return this.http
      .post<any>(environment.apiFullUrl + "/User/UpdateUserScore/" + points + "/" + gamificationActionType, user)
      .toPromise();
  }

  updateUserAmount(user: UserDTO, amount: number): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + "/User/UpdatePaymentAmount/" + amount, user).toPromise();
  }

  updateAreaAmount(areaType: AreaTypeDTO, amount: number): Promise<any> {
    return this.http
      .post<any>(environment.apiFullUrl + "/User/UpdateAreaPaymentAmount/" + amount, areaType)
      .toPromise();
  }
}
