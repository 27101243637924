import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PickerModeEnum } from '@eqproject/eqp-datetimepicker';
import { LinqFilterDTO, LinqPredicateDTO, WherePartType } from '@eqproject/eqp-filters';
import { EqpLookupComponent } from '../../directives/eqp-lookup/eqp-lookup.component';
import { AttachmentDTO } from '../../models/attachment.model';
import { PersonalStatusEnum, Role, UserDTO, UserType } from '../../models/generics/user.model';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es.js';
import Swal from 'sweetalert2';
import { UtilsService } from '../../services/utils.service';

const toBase64 = file => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.toString());
  reader.onerror = error => reject(error);
});

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  submitted = false;
  currentUser: UserDTO = new UserDTO();

  changePasswordForm: FormGroup;
  oldPassword: string;
  password: string;
  confirmedPassword: string;

  roleEnum = Role;
  userTypeEnum = UserType;

  @ViewChild("birthCity", { static: false }) birthCity: EqpLookupComponent;
  @ViewChild("workCity", { static: false }) workCity: EqpLookupComponent;

  //Proprietà per caricamento documento
  image = null;
  documentUrl;
  profileDocumentUrl;
  fileToUpload: File = null;

  // File Readers
  profileImage64: string = "";

  acceptedFileType = ".png";

  pickerModeEnum = PickerModeEnum;

  personalStatusEnum = PersonalStatusEnum;

  dialogChangePasswordRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild('dialogChangePassword', { static: false }) dialogChangePassword: TemplateRef<any>;

  constructor(
    private utilsService: UtilsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.userService.getUserByID(this.authService.getCurrentUser().ID)
      .then((res) => {
        this.documentUrl = res.Document ? this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
        + res.Document.FileDataBase64) : "";
        this.profileDocumentUrl = res?.ProfileDocument?.FileDataBase64 ? this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + res.ProfileDocument.FileDataBase64) : "";
        this.currentUser = res;
        this.createForm();
        //this.createAnimationHeader();

      })
      .catch((err) => {
        DialogService.Error(err.message);
      });

  }

  createAnimationHeader(){
    // let textWrapper = document.querySelector('.ml2');
    // textWrapper.innerHTML = this.currentUser.Name;
  
    let textWrapper = document.querySelector('.ml2');
    let textWrapper3 = document.querySelector('.ml3');
    textWrapper.innerHTML = ("(" + this.utilsService.getAcronymFromUserType(this.currentUser?.UserType) + "_" + this.currentUser.ID+') '+this.currentUser.Name+' '+this.currentUser.Surname).replace(/\S/g, "<span class='letter'>$&</span>");
    textWrapper3.innerHTML = ('Ruolo: '+this.roleEnum[this.currentUser.Role]).replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: false})
      .add({
        targets: '.ml2 .letter',
        scale: [4,1],
        opacity: [0,1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 950,
        delay: (el, i) => 70*i
      }).add({
        targets: '.ml2',
        opacity: 1,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });

      anime.timeline({loop: false})
      .add({
        targets: '.ml3 .letter',
        scale: [4,1],
        opacity: [0,1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 950,
        delay: (el, i) => 70*i
      }).add({
        targets: '.ml3',
        opacity: 1,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });

    // let message = 'PG_'+this.currentUser.ID+' '+this.currentUser.Surname+' '+this.currentUser.Name;
    // let typingPromises = (message, timeout) =>
    //   [...message].map(
    //     (ch, i) =>
    //       new Promise(resolve => {
    //         setTimeout(() => {
    //           resolve(message.substring(0, i + 1));
    //         }, timeout * i);
    //       })
    //   );
    // typingPromises(message, 140).forEach(promise => {
    //   promise.then(portion => {
    //     document.getElementById("teletype").innerHTML = portion;
    //   });
    // });
  }



  createForm() {
    this.profileForm = this.formBuilder.group({
      name: [this.currentUser.Name, Validators.required],
      surname: [this.currentUser.Surname, Validators.required],
      phoneNumber: [this.currentUser.PhoneNumber],
      facebook: [this.currentUser.Facebook],
      personalStatus: [this.currentUser.PersonalStatus],
      email: [this.currentUser.Email, Validators.required],
      birthProvince: [this.currentUser.BirthProvince, Validators.required],
      birthCity: [this.currentUser.BirthCity, Validators.required],
      birthDate: [this.currentUser.BirthDate, Validators.required],
      workProvince: [this.currentUser.WorkProvince],
      workCity: [this.currentUser.WorkCity],
      activity: [this.currentUser.PoliceActivity],
      rank: [this.currentUser.PoliceRank],
      type: [this.currentUser.AreaType],
      userType: [this.currentUser.UserType]
    });
  }

  //Aggiorna i filtri della lookup di BirthCity in base alla scelta della provincia di nascita
  birthProvinceSelected(event) {
    this.currentUser.BirthCity = null;
    if (event != undefined) {
      let birthCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", event.ID, WherePartType.Equal));
      birthCityFilters.push(predicateStatus);
      this.birthCity.dataFilter = birthCityFilters;
      this.birthCity.reloadData();
    }
    else {
      let birthCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", 0, WherePartType.Equal));
      birthCityFilters.push(predicateStatus);
      this.birthCity.dataFilter = birthCityFilters;
      this.birthCity.reloadData();
    }
  }
  //Aggiorna i filtri della lookup di WorkCity in base alla scelta della provincia di lavoro
  workProvinceSelected(event) {
    this.currentUser.WorkCity = null;
    if (event != undefined) {
      let workCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", event.ID, WherePartType.Equal));
      workCityFilters.push(predicateStatus);
      this.workCity.dataFilter = workCityFilters;
      this.workCity.reloadData();
    }
    else {
      let workCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", 0, WherePartType.Equal));
      workCityFilters.push(predicateStatus);
      this.workCity.dataFilter = workCityFilters;
      this.workCity.reloadData();
    }
  }

  onSelectImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      let fileName = event.target.files[0].name;
      let fileExtension = fileName.split('.')[1];

      reader.onload = async (event) => { // called once readAsDataURL is completed
        this.profileDocumentUrl = event.target.result;

        let base64File = await toBase64(this.fileToUpload);

        if (this.currentUser.ProfileDocument == null) {
          this.currentUser.ProfileDocument = new AttachmentDTO();
        }
        this.currentUser.ProfileDocument.ID = 0;
        this.currentUser.ProfileDocument.FileDataBase64 = base64File.split(",")[1];
        this.currentUser.ProfileDocument.FileName = fileName;
        this.currentUser.ProfileDocument.ExtensionFile = fileExtension;
      }
    }
  }

  cancelProfileDocument() {
    this.profileDocumentUrl = "../../../assets/img/user.svg";
    this.currentUser.ProfileDocument = null;
  }

  onSelectDocument(event) {
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      let fileName = event.target.files[0].name;
      let fileExtension = fileName.split('.')[1];

      reader.onload = async (event) => { // called once readAsDataURL is completed
        this.documentUrl = event.target.result;

        let base64File = await toBase64(this.fileToUpload);

        if (this.currentUser.Document == null) {
          this.currentUser.Document = new AttachmentDTO();
        }
        this.currentUser.Document.ID = 0;
        this.currentUser.Document.FileDataBase64 = base64File.split(",")[1];
        this.currentUser.Document.FileName = fileName;
        this.currentUser.Document.ExtensionFile = fileExtension;
        this.currentUser.Document.ContentType = this.fileToUpload.type;
        this.currentUser.Document.AttachmentType = 1;
        this.currentUser.Document.PathDocument = "";
      }
    }
  }

  openDocument() {
    if (this.currentUser.Document.ID == 0) {
      this.userService.downloadTemporaryDocument(this.currentUser.Document);
    } else {
      this.userService.downloadDocument(this.currentUser.Document.ID)
        .then((res) => {
          let blob = new Blob([res], { type: res.type });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.target = "_blank";
          link.href = downloadURL;
          link.click();
        })
    }
  }

  cancelDocument() {
    this.currentUser.Document = null;
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmedPassword: ['', Validators.required],
    });
  }

  openModalChangePassword() {
    this.createChangePasswordForm();
    this.dialogChangePasswordRef = this.dialog.open(this.dialogChangePassword, {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      minWidth: '50%',
      minHeight: '200px'
    });

  }

  changePassword() {
    if (this.confirmedPassword != this.password) {
      DialogService.Error("La password di conferma non corrisponde");
      return
    }
    let data = { ID: this.currentUser.ID, Email: this.currentUser.Email, Password: this.password, OldPassword: this.oldPassword }
    DialogService.Confirm("Una volta modificata la password sarà necessario eseguire nuovamente l'accesso al sistema.", () => {
      this.userService.resetPassword(data)
        .then((res) => {
          DialogService.Success("Password modificata con successo!");
          this.exitChangePassword();
        })
        .catch((err) => {
          DialogService.Error(err.message);
        })
    }, true);
  }

  exitChangePassword() {
    this.dialogChangePasswordRef.close();
  }

  /**
   * Per accedere ai campi del form facilmente
   */
  get f() {
    return this.profileForm.controls;
  }

  /**
   * Al click del pulsante "Salva" invio le modifiche
   * del profilo utente al server
   */
  onSubmit() {
    this.submitted = true;

    // Se il form non è valido mi fermo
    if (this.profileForm.invalid) {
      return;
    }

    let self = this;
    this.userService.saveUser(this.currentUser)
      .then((res) => {
        Swal.fire('Operazione completata con successo.', "", 'success')
          .then(function () {
            self.router.navigate(['core/user-list']);
          });
      })
      .catch((err) => {
        DialogService.Error(err.message);
      })
  }

}
