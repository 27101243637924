import { UserService } from './../../services/user.service';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as signalR from "@microsoft/signalr";
import { environment } from '../../../environments/environment';
import { DialogService } from '../../services/dialog.service';
import { MenuService } from '../../services/menu.service';
import { UserDTO } from '../../models/generics/user.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NotificationDetailDTO } from '../../models/notificationCenter/notificationDetail.model';
import { NotificationService } from '../../services/notification.service';
import { EventHandlerService } from '../../services/eventHandler.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public currentUser: UserDTO;
  public navItems = [];

  //#region Proprietà per gestione notifiche

  notificationCount: number = 0;
  notificationList: Array<NotificationDetailDTO> = new Array<NotificationDetailDTO>();
  notificationReadSubscription: Subscription;
  private hubConnection: signalR.HubConnection
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  // Dialog per la visualizzazione di una notifica
  dialogViewNotificationRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild('dialogViewNotification', { static: false }) dialogViewNotification: TemplateRef<any>;
  selectedNotification: NotificationDetailDTO;
  environment = environment;

  //#endregion

  /**
   * Nasconde la sidebar
   * @param e
  */
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private eventHandlerService: EventHandlerService,
    private menuService: MenuService
  ) {
    this.resetRouteReuseStrategy();
  }

  /**
 * Reimposta la strategia per il reload delle rotte al valore di default.
 * Questo metodo viene richiamato ogni volta che si inizializza il componente, cioè in due momenti: quando viene avviata l'app dopo la login e quando si
 * cambia la company selezionata (in quest'ultimo viene forzato il reload della rotta corrente per potere aggiornare i dati).
 */
  resetRouteReuseStrategy() {
    this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) => { return future.routeConfig === curr.routeConfig };
    this.router.onSameUrlNavigation = 'ignore';
  }

  ngOnInit() {
    //Se è presente l'utente autenticato allora avvia i servizi per la ricezione delle notifiche push
    //if(this.authService.getCurrentUser() != null && this.authService.getCurrentUser() != undefined)
    //{
    this.currentUser = this.authService.getCurrentUser();
    this.startConnection();
    this.NewConnection();
    this.NotificationDataUpdated();
    this.navItems = this.menuService.getMenu();
    //}
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  /**
   * Logout
   */
  logout() {
    this.authService.logout();
  }

  /**
   * Apre la sezione profilo utente
   */
  viewProfile() {
    this.router.navigate(['/profile'], { relativeTo: this.activatedRoute });
  }

  //#region Funzioni per gestione notifiche

  /**
     * Carica le notifiche non lette dell'utente loggato.
     * Se viene passato il parametro a true apre la tendina delle notifiche.
     * @param openDropdown
     */
  loadUnreadNotification(openDropdown: boolean = true) {
    this.notificationList = new Array<NotificationDetailDTO>();
    this.notificationService.getNotifications(true)
      .then(res => {
        this.notificationCount = (res != null && res.length > 0 ? res.length : 0);
        this.notificationList = res;
        if (openDropdown)
          this.trigger.openMenu();
      })
      .catch((err) => { DialogService.Error(err.message); });
  }

  /**
   * Costruisce una connessione con l'hub che gestisce il web socket per l'invio delle notifiche in tempo reale
   * e la avvia.
   */
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + '/NotificationHub')
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.onConnection();
      })
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  /**
   * Una volta aperta la connessione invoca il metodo "Connect" dell'hub per registrare la connessione dell'utente loggato
   */
  public onConnection = () => {
    this.hubConnection.invoke('Connect', this.authService.getCurrentUser().ID, (data) => {
    });
  }


  public NewConnection = () => {
    this.hubConnection.on('MyConnectionId', (data) => {
    });
  }

  /**
   * Metodo chiamato quando l'hub notifica all'utente loggato la ricezione di una nuova notifica.
   * Aumenta il contatore di 1, mostra il toast con il titolo della notifica ricevuta e emette l'evento "NotificationUpdateEvent".
   */
  public NotificationDataUpdated = () => {
    this.hubConnection.on('NotificationDataUpdate', (title) => {
      this.notificationCount = this.notificationCount + 1;
      DialogService.ShowNewNotification(title);
      this.eventHandlerService.broadcast({ name: 'NotificationUpdateEvent', content: '' });
    });
  }

  /**
   * Se viene aperta una notifica non letta salva data/ora di lettura e apre il dialog per la visualizzazione della stessa.
   * @param notificationDetail
   */
  readNotification(notificationDetail: NotificationDetailDTO) {
    if (!notificationDetail.ReadDate) {
      this.notificationService.markAsRead(notificationDetail.ID)
        .then((res) => {
          notificationDetail.ReadDate = res;
          this.eventHandlerService.broadcast({ name: 'NotificationReadEvent', content: { ReadDate: res, ID: notificationDetail.ID } });
        })
        .catch((err) => { DialogService.Error(err.message); });
    }
    this.selectedNotification = notificationDetail;
    this.dialogViewNotificationRef = this.dialog.open(this.dialogViewNotification, {
      disableClose: false,
      hasBackdrop: true,
      autoFocus: false,
      width: '40%',
    });
  }

  /**
   * Redirect alla lista delle notifiche ricevute dall'utente.
   */
  goToNotificationList() {
    this.router.navigate(['/list-notification'], { relativeTo: this.activatedRoute });
  }

  //#endregion

}
