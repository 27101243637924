import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinqPredicateDTO } from '@eqproject/eqp-filters';
import { environment } from '../../environments/environment';
import { NotificationPaginatedDTO } from '../models/generics/notification.model';
import { UserDTO } from '../models/generics/user.model';
import { NotificationDTO, NotificationToUsersDTO } from '../models/notificationCenter/notification.model';
import { NotificationDetailDTO } from '../models/notificationCenter/notificationDetail.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getAllNotificationsToAll(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<NotificationPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<NotificationPaginatedDTO>(environment.apiFullUrl + '/Notification/GetAllNotificationsToAll/' + pageSize + '/' + pageIndex, null).toPromise();
  }

  getAllNotificationsToUser(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<NotificationPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<NotificationPaginatedDTO>(environment.apiFullUrl + '/Notification/GetAllNotificationsToUser/' + pageSize + '/' + pageIndex, null).toPromise();
  }

  getAllNotificationsToArea(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<NotificationPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<NotificationPaginatedDTO>(environment.apiFullUrl + '/Notification/GetAllNotificationsToArea/' + pageSize + '/' + pageIndex, null).toPromise();
  }

  saveNotificationToAll(notificationDTO: NotificationDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/Notification/SaveNotificationToAll', notificationDTO).toPromise();
  }

  saveNotificationToUser(notification: NotificationToUsersDTO, notificationType): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/Notification/SaveNotificationToUser/' + notificationType, notification).toPromise();
  }

  saveNotificationToArea(areaTypeId: number, notificationDTO: NotificationDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/Notification/SaveNotificationToArea/' + areaTypeId, notificationDTO).toPromise();
  }

  getNotifications(onlyUnread: boolean): Promise<Array<NotificationDetailDTO>> {
    return this.http.get<Array<NotificationDetailDTO>>(environment.apiFullUrl + '/Notification/GetNotifications/' + onlyUnread).toPromise();
  }

  markAsRead(id: number): Promise<Date> {
    return this.http.get<Date>(environment.apiFullUrl + '/Notification/MarkAsRead/' + id).toPromise();
  }

  markListAsRead(IDs : number[]) : Promise<any>{
    return this.http.post<any>(environment.apiFullUrl + '/Notification/MarkListAsRead', IDs).toPromise();
  }

}
