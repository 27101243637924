import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-nexi-payment-result',
  templateUrl: './nexi-payment-result.component.html',
  styleUrls: ['./nexi-payment-result.component.css']
})
export class NexiPaymentResultComponent implements OnInit {

  paymentResult: boolean = null;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private paymentService: PaymentService) { }

  ngOnInit(): void {
    if(this.activatedRoute.params != null) {
      this.activatedRoute.params.subscribe((routeParams) => {
        // this.getUserProcessed(routeParams.userStatus, routeParams.userType);
        if(routeParams['result'] != null && routeParams['id'] != null) {
          this.paymentResult = routeParams['result'];
          //Pagamento non andato a buon fine
          if(routeParams['result'] == 'false') {
            DialogService.Error("Il pagamento non è andato a buon fine, ritorna sull'applicazione per effettuare nuovamente la procedura");
          } 
          //Pagamento concluso con successo
          else if(routeParams['result'] == 'true'){
            this.paymentService.updatePaymentDate(routeParams['id']).then(res => {
              DialogService.Success("Pagamento andato a buon fine, ritorna sull'applicazione e fai nuovamente la login per poterla utilizzare");
            })
          }
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
  }

}
