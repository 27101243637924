import { AttachmentType, IAttachmentDTO } from "@eqproject/eqp-attachments";

export class AttachmentDTO implements IAttachmentDTO {
    public ID: number;
    public FileName: string;
    public ContentType: string;
    public ExtensionFile: string;
    public PathDocument: string;
    public AttachmentType: AttachmentType;
    public FileDataBase64: string;
    public FK_Company: number;
    public FK_InsertUser: number;
    public InsertDate: Date;
    public UpdateDate: Date;
    public IsImage: boolean;
  
}
