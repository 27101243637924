import { INavData } from "@coreui/angular";
import { AuthService } from "./auth.service";
import { Role } from "../models/generics/user.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})

/**
 * Servizio di autenticazione
 */
export class MenuService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Restituisce le voci di menù in base al ruolo dell'utente connesso
   */
  getMenu(): Array<INavData> {
    let menu: Array<INavData> = new Array<INavData>();

    let currentUser = this.authService.getCurrentUser();

    //Se il ruolo è admin vedo tutto
    if (
      currentUser?.Role == Role.Superadmin ||
      currentUser?.Role == Role.Admin
    ) {
      menu.push(
        {
          name: "Dashboard",
          url: "/dashboard",
          icon: "fa fa-home",
        },
        {
          name: "Anagrafica",
          url: "anagrafica",
          icon: "fa fa-user",
          children: [
            {
              name: "Lista Utenti",
              url: "/core/user-list",
              icon: "#",
            },
            {
              name: "Verifica operatori",
              url: "/core/users-verification/0/0",
              icon: "#",
            },
            {
              name: "Verifica operatori in bozza",
              url: "/core/users-verification/1/0",
              icon: "#",
            },
            {
              name: "Verifica affiliati",
              url: "/core/users-verification/0/1",
              icon: "#",
            },
            {
              name: "Verifica affiliati in bozza",
              url: "/core/users-verification/1/1",
              icon: "#",
            },
            {
              name: "Verifica civili",
              url: "/core/users-verification/0/2",
              icon: "#",
            },
            {
              name: "Verifica civili in bozza",
              url: "/core/users-verification/1/2",
              icon: "#",
            },
            {
              name: "Duplicati",
              url: "/core/duplicates",
              icon: "#",
            },
          ],
        },
        {
          name: "Bacheca",
          icon: "fa fa-pencil-square-o",
          children: [
            {
              name: "Domande",
              url: "/core/list-questions",
              icon: ""
            },
            {
              name: "Risposte",
              url: "/core/list-answers",
              icon: ""
            },
            {
              name: "Categorie",
              url: "/core/list-categories",
              icon: ""
            }
          ]
        },
        {
          name: "Gamification",
          url: "gamification",
          icon: "fa fa-trophy",
          children: [
            {
              name: "Classifica",
              url: "/core/standing",
              icon: "#",
            },
            {
              name: "Report",
              url: "/core/report",
              icon: "#",
            },
            {
              name: "Assegnazione punteggi",
              url: "/core/score",
              icon: "#",
            },
          ],
        },
        {
          name: 'Sondaggi',
          url: '/core/list-polls',
          icon: "fa fa-bar-chart"
        },
        {
          name: "Log",
          url: "activities",
          icon: "fa fa-tasks",
          children: [
            {
              name: "Attività",
              url: "/core/activities",
              icon: "#",
            },
            {
              name: "Attività collaboratori",
              url: "/core/collaborators-activities",
              icon: "#",
            }
          ],
        },
        {
          name: "Segnalazioni",
          url: "/core/list-reportings",
          icon: "fa fa-file-signature",
        },
        {
          name: "Gestione notifiche",
          url: "/core/list-notifications",
          icon: "fa fa-rss-square",
        },
        {
          name: "Pagamenti",
          url: "payments",
          icon: "fa fa-credit-card",
          children: [
            {
              name: "Imposta Costo App",
              url: "/core/manage-amounts",
              icon: "#",
            },
            {
              name: "Storico transazioni",
              url: "/core/list-payments",
              icon: "#",
            },
          ],
        },
        {
          name: "Link utili",
          url: "/core/useful-links-categories",
          icon: "fa fa-link"
        }
      );

      return menu;
    }

    //se il ruolo è customer vedo solo il contratto in insert e visualizza
    if (
      currentUser?.Role == Role.Sindacalista ||
      currentUser?.Role == Role.Venditore
    ) {
      menu.push({
        name: "Dashboard",
        url: "/dashboard",
        icon: "fa fa-home",
      });

      return menu;
    }

    //se il ruolo è applicant o HD operator vedo solo la parte della lista inviti-control panel
    if (currentUser?.Role == Role.Collaboratore) {
      menu.push(
        {
          name: "Dashboard",
          url: "/dashboard",
          icon: "fa fa-home",
        },
        {
          name: "Anagrafica",
          url: "anagrafica",
          icon: "fa fa-user",
          children: [
            {
              name: "Lista Utenti",
              url: "/core/user-list",
              icon: "#",
            },
            {
              name: "Verifica operatori",
              url: "/core/users-verification/0/0",
              icon: "#",
            },
            {
              name: "Verifica operatori in bozza",
              url: "/core/users-verification/1/0",
              icon: "#",
            },
            {
              name: "Verifica affiliati",
              url: "/core/users-verification/0/1",
              icon: "#",
            },
            {
              name: "Verifica affiliati in bozza",
              url: "/core/users-verification/1/1",
              icon: "#",
            },
            {
              name: "Verifica civili",
              url: "/core/users-verification/0/2",
              icon: "#",
            },
            {
              name: "Verifica civili in bozza",
              url: "/core/users-verification/1/2",
              icon: "#",
            },
            {
              name: "Duplicati",
              url: "/core/duplicates",
              icon: "#",
            },
          ],
        },
        {
          name: "Bacheca",
          icon: "fa fa-pencil-square-o",
          children: [
            {
              name: "Domande",
              url: "/core/list-questions",
              icon: ""
            },
            {
              name: "Risposte",
              url: "/core/list-answers",
              icon: ""
            },
            {
              name: "Categorie",
              url: "/core/list-categories",
              icon: ""
            }
          ]
        },
        {
          name: "Gamification",
          url: "gamification",
          icon: "fa fa-trophy",
          children: [
            {
              name: "Classifica",
              url: "/core/standing",
              icon: "#",
            },
            {
              name: "Report",
              url: "/core/report",
              icon: "#",
            },
            {
              name: "Assegnazione punteggi",
              url: "/core/score",
              icon: "#",
            },
          ],
        },
        {
          name: 'Sondaggi',
          url: '/core/list-polls',
          icon: "fa fa-bar-chart"
        },
        {
          name: "Segnalazioni",
          url: "/core/list-reportings",
          icon: "fa fa-file-signature",
        },
        {
          name: "Pagamenti",
          url: "payments",
          icon: "fa fa-credit-card",
          children: [
            {
              name: "Imposta Costo App",
              url: "/core/manage-amounts",
              icon: "#",
            },
            {
              name: "Storico transazioni",
              url: "/core/list-payments",
              icon: "#",
            },
          ],
        },
        {
          name: "Link utili",
          url: "/core/useful-links-categories",
          icon: "fa fa-link",
        }
      );

      return menu;
    }
  }
}
