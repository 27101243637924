import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PickerModeEnum } from '@eqproject/eqp-datetimepicker';
import { LinqFilterDTO, LinqPredicateDTO, WherePartType } from '@eqproject/eqp-filters';
import { EnumHelper } from '@eqproject/eqp-table';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { EqpLookupComponent } from '../../../../directives/eqp-lookup/eqp-lookup.component';
import { AttachmentDTO } from '../../../../models/attachment.model';
import { PoliceActivity, PoliceRank, AreaType, Role, UserDTO, UserType } from '../../../../models/generics/user.model';
import { AuthService } from '../../../../services/auth.service';
import { DialogService } from '../../../../services/dialog.service';
import { UserService } from '../../../../services/user.service';

const toBase64 = file => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.toString());
  reader.onerror = error => reject(error);
});

@Component({
  selector: 'app-preregistration',
  templateUrl: './preregistration.component.html',
  styleUrls: ['./preregistration.component.scss']
})
export class PreregistrationComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  user: UserDTO = new UserDTO();
  roleEnum = Role;
  roleEnumArray: any[];
  preregister = false;

  pickerModeEnum = PickerModeEnum;

  @ViewChild("birthCity", { static: false }) birthCity: EqpLookupComponent;
  @ViewChild("workCity", { static: false }) workCity: EqpLookupComponent;

  //Proprietà per caricamento documento
  image = null;
  documentUrl;
  profileDocumentUrl;
  fileToUpload: File = null;

  // File Readers
  profileImage64: string = "";

  acceptedFileType = ".png";

  userType: UserType;
  userTypeArray: UserType;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    private enumHelper: EnumHelper,
    private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let roleArray = this.enumHelper.getEnumArray(Role, false, null);
    this.roleEnumArray = roleArray.filter(r => r.key != 0);

    let userType = this.enumHelper.getEnumArray(UserType, false, null);
    this.userTypeArray = userType;

    if (this.activateRoute.snapshot.params["email"] != null) {
    this.userService.getUserByEmail(this.activateRoute.snapshot.params["email"])
      .then((res) => {
        this.documentUrl = "";
        this.profileDocumentUrl = "";
        this.user.ID = res.ID;
        this.user.Name = res.Name;
        this.user.Surname = res.Surname;
        this.user.Email = res.Email;
        this.user.BirthDate = res.BirthDate;
      })
      .catch((err) => {
        DialogService.Error(err.message);
      });
    }
  }

  goToPreregister() {
    this.preregister = true;
    this.createForm();
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      name: [this.user.Name, Validators.required],
      surname: [this.user.Surname, Validators.required],
      email: [this.user.Email, Validators.required],
      phoneNumber: [this.user.PhoneNumber],
      birthProvince: [this.user.BirthProvince, Validators.required],
      birthCity: [this.user.BirthCity, Validators.required],
      birthDate: [this.user.BirthDate, Validators.required],
      workProvince: [this.user.WorkProvince],
      workCity: [this.user.WorkCity],
      role: [this.user.Role],
      facebookName: [this.user.Facebook],
      activity: [this.user.PoliceActivity],
      rank: [this.user.PoliceRank],
      type: [this.user.AreaType],
      userType: [this.user.UserType],
      password: [this.user.Password, Validators.required],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: this.MustMatch('password', 'confirmPassword')
      });
  }

  //Aggiorna l'obbligatorietà dei campi in base al ruolo selezionato
  userTypeChanged() {
    if (this.user.UserType != 0) {
      this.user.WorkProvince = null;
      this.user.WorkCity = null;
      this.user.PoliceActivity = null;
      this.user.PoliceRank = null;
      this.user.AreaType = null;
      this.userForm.controls["workProvince"].clearValidators();
      this.userForm.controls["workProvince"].updateValueAndValidity();
      this.userForm.controls["workCity"].clearValidators();
      this.userForm.controls["workCity"].updateValueAndValidity();
      this.userForm.controls["activity"].clearValidators();
      this.userForm.controls["activity"].updateValueAndValidity();
      this.userForm.controls["rank"].clearValidators();
      this.userForm.controls["rank"].updateValueAndValidity();
      this.userForm.controls["type"].clearValidators();
      this.userForm.controls["type"].updateValueAndValidity();
    }
    else {
      this.userForm.controls["workProvince"].clearValidators();
      this.userForm.controls["workProvince"].setValidators([Validators.required]);
      this.userForm.controls["workProvince"].updateValueAndValidity();
      this.userForm.controls["workCity"].clearValidators();
      this.userForm.controls["workCity"].setValidators([Validators.required]);
      this.userForm.controls["workCity"].updateValueAndValidity();
      this.userForm.controls["activity"].clearValidators();
      this.userForm.controls["activity"].setValidators([Validators.required]);
      this.userForm.controls["activity"].updateValueAndValidity();
      this.userForm.controls["rank"].clearValidators();
      this.userForm.controls["rank"].setValidators([Validators.required]);
      this.userForm.controls["rank"].updateValueAndValidity();
      this.userForm.controls["type"].clearValidators();
      this.userForm.controls["type"].setValidators([Validators.required]);
      this.userForm.controls["type"].updateValueAndValidity();
    }
  }

  //Aggiorna i filtri della lookup di BirthCity in base alla scelta della provincia di nascita
  birthProvinceSelected(event) {
    this.user.BirthCity = null;
    if (event != undefined) {
      let birthCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", event.ID, WherePartType.Equal));
      birthCityFilters.push(predicateStatus);
      this.birthCity.dataFilter = birthCityFilters;
      this.birthCity.reloadData();
    }
    else {
      let birthCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", 0, WherePartType.Equal));
      birthCityFilters.push(predicateStatus);
      this.birthCity.dataFilter = birthCityFilters;
      this.birthCity.reloadData();
    }
  }

  //Aggiorna i filtri della lookup di WorkCity in base alla scelta della provincia di lavoro
  workProvinceSelected(event) {
    this.user.WorkCity = null;
    if (event != undefined) {
      let workCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", event.ID, WherePartType.Equal));
      workCityFilters.push(predicateStatus);
      this.workCity.dataFilter = workCityFilters;
      this.workCity.reloadData();
    }
    else {
      let workCityFilters = new Array<LinqPredicateDTO>();
      let predicateStatus: LinqPredicateDTO = new LinqPredicateDTO();
      predicateStatus.PropertyFilters = new Array<LinqFilterDTO>();
      predicateStatus.PropertyFilters.push(LinqFilterDTO.createFilter("FK_Province", 0, WherePartType.Equal));
      workCityFilters.push(predicateStatus);
      this.workCity.dataFilter = workCityFilters;
      this.workCity.reloadData();
    }
  }

  onSelectImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      let fileName = event.target.files[0].name;
      let fileExtension = fileName.split('.')[1];

      reader.onload = async (event) => { // called once readAsDataURL is completed
        this.profileDocumentUrl = event.target.result;

        let base64File = await toBase64(this.fileToUpload);

        if (this.user.ProfileDocument == null) {
          this.user.ProfileDocument = new AttachmentDTO();
        }
        this.user.ProfileDocument.ID = 0;
        this.user.ProfileDocument.FileDataBase64 = base64File.split(",")[1];
        this.user.ProfileDocument.FileName = fileName;
        this.user.ProfileDocument.ContentType = this.fileToUpload.type;
        this.user.ProfileDocument.ExtensionFile = fileExtension;
      }
    }
  }


  cancelProfileDocument() {
    this.profileDocumentUrl = "../../../assets/img/user.svg";
    this.user.ProfileDocument = null;
  }

  onSelectDocument(event) {
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      let fileName = event.target.files[0].name;
      let fileExtension = fileName.split('.')[1];

      reader.onload = async (event) => { // called once readAsDataURL is completed
        this.documentUrl = event.target.result;
        //this.profileImage64 = btoa(this.url);

        let base64File = await toBase64(this.fileToUpload);

        if (this.user.Document == null) {
          this.user.Document = new AttachmentDTO();
        }
        this.user.Document.ID = 0;
        this.user.Document.FileDataBase64 = base64File.split(",")[1];
        this.user.Document.FileName = fileName;
        this.user.Document.ExtensionFile = fileExtension;
        this.user.Document.ContentType = this.fileToUpload.type;
        this.user.Document.AttachmentType = 1;
        this.user.Document.PathDocument = "";
      }
    }
  }

  downloadDocument() {
    if (this.user.Document.ID == 0) {
      this.userService.downloadTemporaryDocument(this.user.Document);
    } else {
      this.userService.downloadDocument(this.user.Document.ID)
        .then((res) => {
          let blob = new Blob([res], { type: res.type });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.target = "_blank";
          link.href = downloadURL;
          link.click();
        })
    }
  }

  cancelDocument() {
    this.user.Document = null;
  }

  save() {
    this.submitted = true;

    // Se il form non è valido mi fermo
    if (this.userForm.invalid) {
      return;
    }

    this.userService.preregistration(this.user)
      .then((res) => {
        DialogService.Success("Preregistrazione effettuata");
        Swal.fire('Operazione completata con successo.', "Preregistrazione effettuata", 'success')
          .then(function () {
            window.open("https://www.puntato.com/", '_self');
          });
      })
      .catch((err) => {
        DialogService.Error(err.message);
      })
  }

  openImage() {
    this.userService.downloadTemporaryDocument(this.user.ProfileDocument);
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      //Se ci sono già errori non c'è bisogno di controllare altro
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      //Se la validazione fallisce imposto l'errore a true
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}