import { Component, OnInit } from '@angular/core';
import anime from "animejs";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      var animeCus = {
        opacityIn: [0, 1],
        scaleIn: [0.2, 1],
        durationIn: 1200,
        delay: 500
      };
      anime.timeline({ loop: false })
        .add({
          targets: '.custom-animation .letters-1',
          opacity: animeCus.opacityIn,
          scale: animeCus.scaleIn,
          duration: animeCus.durationIn
        });
    }, 800);

    setTimeout(() => {
      var animeCus = {
        opacityIn: [0, 1],
        scaleIn: [0.2, 1],
        durationIn: 1200,
        delay: 500
      };
      anime.timeline({ loop: false })
        .add({
          targets: '.custom-animation2 .letters-2',
          opacity: animeCus.opacityIn,
          scale: animeCus.scaleIn,
          duration: animeCus.durationIn
        });
    }, 800);

    let element = document.querySelector('.footerFloating');
    anime({
      targets: element,
      height: '100%',
      easing: 'easeInOutQuad',
      begin: function () {
        //  document.getElementById("cta-button").style.display = "none";
        //  document.getElementById("cta-text").style.display = "none";     





      }
    });

  }

}