import { Injectable } from '@angular/core';
import { UserDTO, UserType } from '../models/generics/user.model';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }


  copytext(textToCopy){
    navigator.clipboard.writeText(textToCopy);
  }
  
  //Restituisce una stringa contenente l'acronimo in base al tipo di utente
  getAcronymFromUserType(userType: UserType) {
    switch (userType) {
      case UserType.Operatore:
        return "PG";
      case UserType.Civile:
        return "CIV";
      case UserType.Affiliato:
        return "AFF";
    }
  }


  getBlobFromUrl = (myImageUrl) => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve(request.response);
      };
      request.onerror = reject;
      request.send();
    });
  };


  showIfIsOldGuard(userProcessed: UserDTO){
    let result = false;
    let subDate = new Date(userProcessed.SubscriptionDate);
    let lastSubDateGuard = new Date('2018-09-03T23:24:22');
    if(subDate > lastSubDateGuard){
      result = false;
    }else{
      result = true;
    }
    return result
  }


}
