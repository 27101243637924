import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinqPredicateDTO } from '@eqproject/eqp-filters';
import { environment } from '../../environments/environment';
import { PaymentDTO, PaymentsPaginatedDTO } from '../models/registry/payment.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  getAllPayments(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<PaymentsPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<PaymentsPaginatedDTO>(environment.apiFullUrl + '/Payment/GetAllPayments/' + pageSize + '/' + pageIndex, filters).toPromise();
  }

  savePayment(payment: PaymentDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/Payment/SavePayment', payment).toPromise()
  }

  deletePayment(id: number): Promise<any> {
    return this.http.delete<any>(environment.apiFullUrl + '/Payment/' + id).toPromise();
  }

  updatePaymentDate(id: number): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/Payment/UpdatePaymentDate/' + id, null).toPromise();
  }

  getUrlNexi(id: number):Promise<any>{
    return this.http.get<any>(environment.apiFullUrl + '/Payment/GetUrlNexi/' + id).toPromise();
  }

  getInfoPayment() {
    let HTTPOptions: Object = {
      responseType: 'blob'
    }
    return this.http.get<any>(environment.apiFullUrl + '/Payment/GetInfoPayment', HTTPOptions).toPromise();
  }

  updatePaymentDateByAdmin(id: number) {
    return this.http.get<any>(environment.apiFullUrl + '/Payment/UpdatePaymentDateByAdmin/' + id).toPromise();
  }

}
