import { Injectable } from '@angular/core';
import { LinqPredicateDTO } from '@eqproject/eqp-filters';
import { HttpClient } from '@angular/common/http';
import { ActionActivityLogDTO, ActivityLogPaginatedDTO } from '../models/generics/actionActivityLog.model';
import { environment } from '../../environments/environment';
import { UserDTO } from '../models/generics/user.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {


  constructor(private http: HttpClient) { }  

  getAllActivities(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<ActivityLogPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<ActivityLogPaginatedDTO>(environment.apiFullUrl + '/ActivityLog/GetAllActivities/' + pageSize + '/' + pageIndex, filters).toPromise();
  }

  getAllCollaboratorsActivities(pageSize: number, pageIndex: number, filters?: Array<LinqPredicateDTO>): Promise<ActivityLogPaginatedDTO> {

    if (filters == null || filters == undefined)
      filters = new Array<LinqPredicateDTO>();

    return this.http.post<ActivityLogPaginatedDTO>(environment.apiFullUrl + '/ActivityLog/GetAllCollaboratorsActivities/' + pageSize + '/' + pageIndex, filters).toPromise();
  }

  addActivity(user: UserDTO): Promise<any> {
    return this.http.post<any>(environment.apiFullUrl + '/ActivityLog/WriteActionLog', user).toPromise();
  }

}
