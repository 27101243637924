import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UserDTO } from "../../models/generics/user.model";
import { DialogService } from "../../services/dialog.service";
import { PaymentService } from "../../services/payment.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-app-payment",
  templateUrl: "./app-payment.component.html",
  styleUrls: ["./app-payment.component.scss"]
})
export class AppPaymentComponent implements OnInit {
  currentUser: UserDTO;
  nexiUrlPayment: any;

  @ViewChild("nexiPaymentTemplate") nexiPaymentTemplate: TemplateRef<any>;
  nexiPaymentTemplateRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params != null && params["id"] != null) {
        this.currentUser = await this.userService.getUserByID(params["id"]);
      }
    });
  }

  async openNexi() {
    let urlToClear = await this.paymentService.getUrlNexi(this.currentUser.ID);
    this.nexiUrlPayment = this.sanitizer.bypassSecurityTrustResourceUrl(urlToClear);

    this.nexiPaymentTemplateRef = await this.dialog.open(this.nexiPaymentTemplate, {
      minWidth: "100%",
      minHeight: "100%",
      panelClass: "nexi-payment-modal"
    });
    this.nexiPaymentTemplateRef.afterClosed().subscribe((d) => {
      if (d != null) {
        if (d.paymentConfirmed) {
          this.paymentService
            .updatePaymentDate(this.currentUser.ID)
            .then((res) => {
              // DialogService.Success("Pagamento completato con successo! Torna sull'applicazione ed effettua la login per poterla utilizzare");
              this.router.navigate(["/nexi-payment-result/true/" + this.currentUser.ID]);
            })
            .catch((err) => {
              DialogService.Error(err.message);
            });
        } else if (d.paymentCancelled) {
          DialogService.Error("Errore nel pagamento! Riprovare");
          // this.router.navigate(['/nexi-payment-result/false/' + this.currentUser.ID]);
        }
      }
    });
  }

  onLoadFrame(event) {
    if (
      event != null &&
      event.currentTarget != null &&
      event.currentTarget.contentWindow != null &&
      event.currentTarget.contentWindow.location != null &&
      event.currentTarget.contentWindow.location.href != null
    ) {
      //Caso in cui il pagamento non sia andato a buon fine
      if (event.currentTarget.contentWindow.location.href.includes("nexi-payment-result/false")) {
        this.nexiPaymentTemplateRef.close({ paymentCancelled: true });
      }
      //Caso in cui il pagamento sia stato completato con successo
      else if (event.currentTarget.contentWindow.location.href.includes("nexi-payment-result/true")) {
        this.nexiPaymentTemplateRef.close({ paymentConfirmed: true });
      }
    }
  }

  showInfoPayment() {
    this.paymentService.getInfoPayment().then((res) => {
      let blob = new Blob([res], { type: res.type });
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.target = "_blank";
      link.href = downloadURL;
      link.click();
      // let fileName = "Informativa pagamenti.pdf";
      // File.writeFile(
      //   File.dataDirectory,
      //   fileName,
      //   blob,
      //   {
      //     replace: true,
      //   }
      // ).then(() => {
      //   this.fileOpener.open(File.dataDirectory + fileName, "application/pdf");
      // })
    });
  }
}
