import { AttachmentDTO } from "../attachment.model";
import { LookupDTO } from "../lookup.model";
import { GlobalRankingDTO } from "./global-ranking.model";
import { UserRankingDTO } from "./userRanking.model";

/**
 * Modello per memorizzare i dati dell'utente
 */
export class UserDTO {
  ID: number;
  Name: string;
  Surname: string;
  Email: string;
  Password: string;
  PhoneNumber: string;
  ChangedPassword: boolean;
  SubscriptionDate: Date;
  Code: string;
  BirthDate: Date;
  BirthCity: LookupDTO;
  BirthProvince: LookupDTO;
  AreaType: LookupDTO;
  Region: string;
  WorkCity: LookupDTO;
  WorkProvince: LookupDTO;
  PoliceRank: LookupDTO;
  CityLastSchool: string;
  PoliceActivity: LookupDTO;
  Facebook: string;
  FK_InvitingUser: number;
  UserStatus: UserStatus;
  TotalGamificationPoint: number;
  SpentPoints: number;
  Role: Role;
  Document: AttachmentDTO;
  FK_Document: number;
  FK_ProfileDocument: number;
  ProfileDocument: AttachmentDTO;
  PersonalStatus: PersonalStatusEnum;
  UserType: UserType;
  VacationDay: number;
  IsWorkingOnSaturday: boolean;
  IsWorkingHoliday: boolean;
  FK_InsertUser: number;
  InsertDate: Date;
  VerificationToklen: string;
  VerifyDate: Date;
  ReverifyDate: Date;
  UserRanking: UserRankingDTO;
  RegistrationDate: Date;
  MaxUserToInvite: number;
  CountUserInvited: number;
  RefusalMotivation: string;
  PaymentAmount: number;
  PaymentDate: Date;
  PaymentExpiringDate: Date;

  /**
   * Proprietà per salvare un documento (anche sul db) in forma temporanea, in modo da poter
   * fare i controlli lato backend e, nel caso, sovrascrivere il Document effettivo
   */
  TmpDocument: AttachmentDTO;
}

export class UsersPaginatedDTO {
  NumberOfElements: number;
  UsersDTO: Array<UserDTO>;
}

export class StandingUsersPaginatedDTO {
  NumberOfElements: number;
  StandingUsersDTO: Array<GlobalRankingDTO>;
}

export enum Role {
  "Superadmin",
  "Collaboratore",
  "Sindacalista",
  "Venditore",
  "Utente",
  "Admin"
}

export enum UserStatus {
  "Da Verificare" = 0,
  "Da Verificare in Bozza" = 1,
  "Rifiutato" = 2,
  "Disabilitato" = 3,
  "Abilitato" = 4, //UTENTE CHE NON HA ANCORA FATTO INVITI
  "Abilitato Affidabile" = 5, //UTENTE CHE HA FATTO INVITI E CHE è AFFIDABILE VISTO CHE GLI INVITI COINCIDONO
  "Abilitato Non Affidabile" = 6, //UTENTE CHE HA FATTO INVITI E CHE NON è AFFIDABILE VISTO CHE GLI INVITI SONO FALSI
  "Prospect" = 7
}

export enum PoliceActivity {
  "Pattuglia 100",
  "Pattuglia 50 - Ufficio 50",
  "Ufficio 100",
  "Reparti speciali",
  "Battaglioni reparti mobili",
  "Altro"
}

export enum PoliceRank {
  "Allievo",
  "Agente scelto",
  "Assistente",
  "Assistente capo",
  "Vice sovrintendente",
  "Sovrintendente",
  "Sovrintendente capo",
  "Vice ispettore",
  "Ispettore",
  "Ispettore capo",
  "Ispettore superiore",
  "Sostituto commisario",
  "Vice commisario",
  "Commissario",
  "Commissario capo",
  "Vice questore aggiunto",
  "Primo dirigente",
  "Dirigente generale"
}

//OPERATORI
export enum AreaType {
  "Carabinieri",
  "Polizia di stato",
  "Guardia di finanza",
  "Polizia locale municipale",
  "Corpo forestale",
  "Polizia penitenziaria",

  "Capitaneria di porto",
  "Esercito",
  "Aviazione",
  "Vigili del fuoco",
  "Guardia costiera",
  "Avvocati",
  "Operatori Sanitari",
  "Marina",
  "Aeronautica",

  "Operatori di Polizia Estero",
  "Civile",
  "Altro"
}

export enum UserType {
  "Operatore",
  "Affiliato",
  "Civile"
}

export enum PersonalStatusEnum {
  "In servizio",
  "Libero dal servizio",
  "Ferie",
  "Privato"
}
